@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .solidBorder {
        @apply border-[1px] border-solid w-full p-4;
    }
    .title-text {
        @apply w-full py-3 text-white font-bold text-center uppercase
    }
}

@layer components {
    .button {
        @apply px-4 py-2 rounded-sm text-white cursor-pointer;
    }
    .item-header {
        @apply p-2 text-white border-[1px] border-collapse border-solid border-gray-400
    }
}

@layer components {
    .bordered {
    @apply border-[3px] border-solid uppercase ;
}
    .item {
        @apply w-full p-2 min-h-[52px] text-center flex items-center justify-center
    }
    .static-data {
       @apply w-full md:w-1/3 lg:w-1/4 xl:w-1/6
    }
}
