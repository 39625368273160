@layer elements {
    .upload__image-wrapper {
        @apply w-full !important
    }
    .button_wrapper{
        @apply flex gap-4 mb-2
    }

    .button_wrapper > span {
        @apply py-1 px-2 rounded text-white cursor-pointer
    }

    .button_wrapper > span:nth-child(1) {
        @apply bg-blue-500
    }
    .button_wrapper > span:nth-child(2) {
        @apply bg-red-500
    }
    .image_preview {
        @apply h-64 object-contain !important
    }
    .image-item__btn-wrapper {
        @apply flex gap-2 absolute top-2 left-2 items-center
    }

    .image-item__btn-wrapper span{
        @apply p-1 text-white cursor-pointer
    }

    .image-item__btn-wrapper > span:nth-child(1) {
        @apply bg-yellow-500
    }

    .image-item__btn-wrapper > span:nth-child(2) {
        @apply bg-red-500
    }

    .image-item {
        @apply relative w-1/3
    }
    .preview_container {
        @apply flex gap-y-4 flex-wrap
    }
}
