@layer news {
    .card__container {
        @apply w-full relative;
    }
    .card__news {
        @apply !border !border-[#2d5c6a] !shadow rounded-md p-4 w-full !mx-auto;
    }
    .bg__col {
        @apply bg-slate-700/80 rounded text-white p-4;
    }
    .bg__link {
        @apply bg-slate-700/80 rounded !text-white p-4 max-w-full flex ;
    }
    .banner {
        @apply absolute top-0 -right-2 h-8 flex items-center justify-center w-[13%] text-center text-white font-bold;
        clip-path: polygon(0% 0%, 100% 0%, 95% 100%, 0% 100%);
    }
}
