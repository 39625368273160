.twailind-progress-bar {
    width: 20%;
    height: 10px;
}
 
/* Style supplémentaire pour le curseur */
.twailind-range-input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px; 
    height: 20px;
    background-color: #ffffff; 
    border: 2px solid #007bff; 
    border-radius: 50%;
    cursor: pointer;
}
