@layer ContactDropdown {
    .title {
        @apply font-semibold text-start border-solid border-r-2 border-[#2d5c6a] w-1/6
    }
    .content {
        @apply px-4
    }
    .option {
        @apply px-2 py-1 border-2 border-solid border-gray-600 bg-green-600 text-white font-bold rounded-md cursor-pointer
    }
}
