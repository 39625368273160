@layer dpe {
    .dpe {
        @apply flex items-center justify-center text-white font-bold text-2xl w-full h-full text-right cursor-pointer;
        clip-path: polygon(0% 0%,
                  60% 0%,
                  95% 0%,
                 100% 50%,
                  95% 100%,
                  60% 100%,
                   0% 100%);
    }
    .bordered {
        @apply !border-[1px] !border-solid !border-black;
    }
    .content {
        @apply col-span-6  overflow-x-hidden text-right;
    }
}
