@layer membership {
    .membership__container {
        @apply min-h-[500px] border-2 border-solid border-gray-300 !important;
        @apply flex items-center justify-between px-8;
    }
    .image__payment {
        @apply w-1/2 object-fill
    }
    .indication__container {
        @apply text-2xl text-center
    }
    .button__container {
        @apply flex justify-center mt-6;
    }
}
