@layer formBuilder {
    .title {
        @apply w-full uppercase p-4 text-center;
        @apply text-3xl font-extrabold  !important;
    }
    .pagination {
        @apply flex flex-wrap items-center justify-center gap-x-4
    }
    .pagination__link {
        @apply text-white bg-gray-600/40 font-bold p-4 rounded-full w-12 h-12 flex items-center justify-center
    }
    .button__handling {
        @apply text-white focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 !important;
    }
}
