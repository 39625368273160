
@layer view {
    .container-view {
        /* @apply w-full p-2 border-[1px] border-solid border-black/50 !important; */
    }
    .item-container {
        @apply flex items-center justify-center flex-wrap my-4
    }
    .input {
        @apply outline-none border-2 border-solid border-black/20 p-2 w-full sm:w-2/4 !important
    }
    .error-message {
        @apply w-full sm:text-right sm:w-3/4 text-red-600
    }

    .container-view  [type=radio] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        width: 100%;
        left: 10%;
      }

      .container-view  [type=radio] + div {
        cursor: pointer;
        border-radius: 5px;
      }

      .container-view  [type=radio]:checked + div {
        transition: all 200ms;
        transition-timing-function: ease-in-out;
        outline: 1px solid rgb(116, 116, 120);
        background-color: rgb(45, 92, 106);
        color: white;
      }
}
