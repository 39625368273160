@layer card {
    .portal__card {
        @apply w-full sm:w-72 h-52 !border border-solid border-black overflow-hidden cursor-pointer !shadow-lg shadow-black/40;
    }
    .portal__header {
        @apply flex items-center justify-between p-1 h-1/4
    }
    .portal__info {
        transition: background-color 0.5s ease-out allow-discrete;
        @apply h-2/4 flex items-center justify-center;
    }
    .portal__card:hover > .portal__header
    {
        transition: background-color 0.5s ease-out allow-discrete;
        @apply bg-black/70 text-white
    }
    .portal__img {
        @apply h-3/4 flex items-center justify-center mt-4
    }
}
