@layer stepForm {
    .container {
        @apply flex flex-wrap gap-x-8 items-center my-4
    }
    .step__title {
        @apply w-full text-center text-white uppercase my-2 p-3 font-bold text-2xl !important;
    }

    .suggestion {
        @apply px-4 py-[1px] bg-blue-500 text-white my-1 rounded cursor-pointer
    }
    .simple__input {
        @apply p-2 border-[2px] border-solid border-blue-300 my-4 w-full !important
    }
    .simple__input:focus {
        @apply outline-blue-500 !important
    }
    .file__input {
        @apply hidden
    }
    .text__area {
        @apply w-full border-[1px] border-solid my-2 rounded h-44 p-2 !important
    }
}
